import {Select, DatePicker, Icon, Button, Tooltip, Switch} from 'antd';
import moment, {Moment} from 'moment';
import * as React from 'react';

import {getCampaignTypes, CONSTANTS} from '../../utils';
import './connectionsFilter.css';

const {Option} = Select;
const {RangePicker} = DatePicker;

const dateOptions = [
  {
    label: 'Lifetime',
    value: 'lifetime',
  },
  {
    label: 'Last 30 days vs last previous 30 days',
    value: 'last_30_days_vs_prev_30_days',
    className: 'connections-page__last30vsprev30-option',
  },
  {
    label: 'Last 7 days',
    value: 'last_7_days',
  },
  {
    label: 'Last 30 days',
    value: 'last_30_days',
  },
  {
    label: 'Last 3 Months',
    value: 'last_3_months',
  },
  {
    label: 'Last 6 months',
    value: 'last_6_months',
  },
  {
    label: 'Last 12 months',
    value: 'last_12_months',
  },
];

const CAMPAIGN_TYPES = [
  CONSTANTS.campaignTypes.acquisition,
  CONSTANTS.campaignTypes.acquisition_csv,
  CONSTANTS.campaignTypes.acquisition_via_import,
  CONSTANTS.campaignTypes.retention,
  CONSTANTS.campaignTypes.retention_csv,
  CONSTANTS.campaignTypes.retention_actions,
  CONSTANTS.campaignTypes.retention_tags,
  CONSTANTS.campaignTypes.acquisition_inmail,
];

export const ConnectionsFilter = ({
  connections,
  users,
  campaigns,
  setFilters,
  filters,
  totalConnections,
  selectedRows,
  connectionsLoading,
}: any) => {
  const [showCustom, setShowCustom] = React.useState(false);
  const [showDatesSelect, setShowDatesSelect] = React.useState(false);

  const filterCampaigns = filters?.user?.length
    ? campaigns.filter(c => filters.user.includes(c.selectedUser?.id.toString()))
    : campaigns;

  const handleUsersChange = user => {
    setFilters({
      ...filters,
      user,
      start: 0,
    });
  };

  const handleCampaignsChange = campaign => {
    setFilters({
      ...filters,
      campaign,
      start: 0,
    });
  };
  const handleCampaignsChangeType = campaign_type => {
    setFilters({
      ...filters,
      campaign_type,
      start: 0,
    });
  };
  const onChangeClickedFilter = clicked => {
    if (clicked) {
      setFilters({
        ...filters,
        clicked,
      });
    } else {
      delete filters.clicked;
      setFilters({
        ...filters,
      });
    }
  };

  const removeSearchFilter = () => {
    delete filters.receiver_id;
    setFilters({
      ...filters,
    });
  };

  const lastTwoMonthRange: [Moment, Moment] = [moment().subtract(1, 'months'), moment()];
  const disableFutureDates = currentDate => currentDate && currentDate >= moment().endOf('day');

  const handleDateChange = date => {
    setShowCustom(false);
    setFilters({
      ...filters,
      date,
      date_range_start: '',
      date_range_end: '',
      start: 0,
    });
  };

  const customDates = arg => {
    setShowCustom(false);
    setShowDatesSelect(false);
    let [start, end] = arg;
    start = start.format('YYYY-MM-DD');
    end = end.format('YYYY-MM-DD');
    setFilters({
      ...filters,
      date: `${start} - ${end}`,
      date_range_start: start,
      date_range_end: end,
      start: 0,
    });
  };

  return (
    <div className="connections-page__filters">
      <Select
        loading={connectionsLoading}
        mode="multiple"
        placeholder="Select users"
        onChange={handleUsersChange}
        value={users.length > 0 ? filters?.user : undefined}
        className="connections-page__filter-input"
      >
        {users.map(user => (
          <Option key={user.id}>{user.first_name}</Option>
        ))}
      </Select>

      <Select
        loading={connectionsLoading}
        mode="multiple"
        placeholder="Select campaign"
        onChange={handleCampaignsChange}
        value={filterCampaigns.length > 0 ? filters?.campaign : undefined}
        filterOption={(input, option) =>
          (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="connections-page__filter-input"
      >
        {[...filterCampaigns].map((c: any) => (
          <Option key={c.id}>{c.name}</Option>
        ))}
      </Select>

      <Select
        placeholder="Select time"
        open={showDatesSelect}
        value={filters?.date}
        className="connections-page__filter-input"
        onDropdownVisibleChange={open => {
          if (open) {
            setShowDatesSelect(true);
          } else if (!open && !showCustom) {
            setShowDatesSelect(false);
          }
        }}
        onChange={handleDateChange}
        dropdownRender={menu => (
          <div>
            {menu}
            <div
              className="custom-range"
              onMouseDown={e => e.preventDefault()}
              onBlur={() => {
                setShowDatesSelect(false);
                setShowCustom(false);
              }}
            >
              <div onClick={() => setShowCustom(true)} className="custom-range__button">
                Custom
              </div>
              <RangePicker
                className="custom-range__datepicker"
                open={showCustom}
                onChange={customDates}
                defaultValue={lastTwoMonthRange}
                disabledDate={disableFutureDates}
              />
            </div>
          </div>
        )}
      >
        {dateOptions.map(item => (
          <Option className={item.className} key={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>

      <Select
        mode="multiple"
        placeholder="Select type"
        onChange={handleCampaignsChangeType}
        value={filters?.campaign_type}
        filterOption={(input, option) =>
          (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className="connections-page__filter-input"
      >
        {CAMPAIGN_TYPES.map(type => (
          <Option key={type} className="ghp">
            {getCampaignTypes(type).value}
          </Option>
        ))}
      </Select>

      <div className="connections-page__clicked-filter">
        <Tooltip title="Show only connections who clicked on the link"><span>Clicked only:</span></Tooltip>
        <Switch defaultChecked onChange={val => onChangeClickedFilter(val ? 1 : 0)} />
      </div>

      {filters.receiver_id && (
        <div className="connections-page__clicked-filter">
          <Icon type="search" />
          <Tooltip title={filters.receiver_id}>
            <span>Receiver: {filters.receiver_id}</span>
          </Tooltip>
          <Button icon="close" shape="circle" size="small" onClick={removeSearchFilter} />
        </div>
      )}
      <span className="connections-page__selected-count">
        {connectionsLoading ? (
          <span>
            <Icon type="loading" /> loading...
          </span>
        ) : (
          `Showing ${connections?.length} of ${totalConnections} (${selectedRows?.length} Selected)`
        )}
      </span>
    </div>
  );
};
