import {Spin, Icon, Button} from 'antd';
import React from 'react';

import {STRINGS, getUserErrorMessageByCode, replaceKeys, brandConfig} from '../../utils';
import {NoVNCConnection} from '../noVNCConnection/NoVNCConnection';
import {Timer} from '../timer/timer';
import './userValidateStep.css';

export const UserValidateStep = ({userEmail, validationInfo, validateUser, client}: any) => {
  const {user: userId, status: step, additional_data} = validationInfo;
  const {validationErrorCode} = additional_data || {};

  const onRetry = () => {
    validateUser({user_id: userId});
  };

  const defaultStep = text => {
    return (
      <div className="validate-user-modal__spin">
        <Spin size="large" />
        <div className="validate-user-modal__spin-text">{text}</div>
      </div>
    );
  };

  const steps = {
    created: function () {
      return defaultStep(
        'Starting Validation (The total process will take up to 5 minutes, please don’t close in the middle)'
      );
    },
    in_progress: function () {
      return defaultStep('Requesting code from LinkedIn (Wait, it usually takes up to 1 minute)');
    },
    requests_code: function () {
      return (
        <div className="validate-user-modal__code">
          <Timer initialMinutes={20} />
          <NoVNCConnection client={client} options={{showCustomAddressBar: false}} />
        </div>
      );
    },
    typing_code: function () {
      return defaultStep('Sending code to LinkedIn for Verification (Wait, it usually takes up to 1 minute)');
    },
    user_validation: function () {
      return defaultStep('We are checking your LinkedIn profile settings (Wait, it usually takes up to 2 minutes)');
    },
    done: function () {
      return (
        <div className="validate-user-modal__success">
          <Icon type="check-circle" />
          <div className="validate-user-modal__success-text">{`User ${userEmail} validated successfully!`}</div>
        </div>
      );
    },
    failed: function () {
      const templateMsg = getUserErrorMessageByCode(validationErrorCode);
      const errorMsg = replaceKeys(
        templateMsg,
        ['{user.email}', '{brandConfig.contact}'],
        [userEmail, brandConfig.contact]
      );

      return (
        <div className="validate-user-modal__error">
          <Icon type="warning" />
          <div className="validate-user-modal__error-text">{errorMsg}</div>
          <Button className="validate-user-modal__try-again" onClick={onRetry}>
            Try Again
          </Button>
        </div>
      );
    },
    error: function () {
      const templateMsg = getUserErrorMessageByCode(validationErrorCode) || STRINGS.errors.user.error_default;
      const errorMsg = replaceKeys(
        templateMsg,
        ['{user.email}', '{brandConfig.contact}'],
        [userEmail, brandConfig.contact]
      );

      return (
        <div className="validate-user-modal__error">
          <Icon type="warning" />
          <div className="validate-user-modal__error-text">{errorMsg}</div>
          <Button className="validate-user-modal__try-again" onClick={onRetry}>
            Try Again
          </Button>
        </div>
      );
    },
  };

  return steps[step]();
};
