import {Button} from 'antd';
import moment from 'moment';
import * as React from 'react';

import {User, Client} from '@growth-x/types';
import {brandConfig, checkCloudOffline} from '@growth-x/ui';

import {InfoIcon} from '../';
import {CONSTANTS} from '../..';
import {isUserWithRecentLoginProblem} from '../../utils';
import {ConversationMessageAttachment} from './ConversationMessageAttachment';
import {MessageContent} from './messageContent';

type TrackingLink = {
  click_count: number;
  original_url: string;
  url: string;
};

interface ConversationMessageProps {
  message: any;
  updateMessageStatus: (params: any) => void;
  messageAttachment?: any;
  user: User;
  client: Client;
  serverState?: string;
  members: any[];
  trackingLinks?: TrackingLink[];
  getPreview: (urls: string[]) => any[];
  addCustomEmailToUserReceiver: (params: any) => any;
  addCustomPhoneToUserReceiver: (params: any) => any;
  userReceiverId: any;
}

export class ConversationMessage extends React.Component<ConversationMessageProps> {
  getStatusDescription = (status: string) => {
    if (status === 'created') {
      return 'waiting to be sent';
    } else if (status === 'in_progress') {
      return 'message is sending';
    } else if (status.includes('error')) {
      return 'error';
    }
    return '';
  };

  getErrorMessage = (status: string, user: User) => {
    switch (status) {
      case 'error_profile_limit_reached':
        return `This user (${
          user.email
        }) has reached the profile views limit for today, this message will be sent automatically tomorrow. ${
          !brandConfig.isAffiliate &&
          `Please read this article for a detailed explanation: ${brandConfig.articles.profileViewLimit}`
        }`;
      case 'error_response_or_manual_detected':
        return 'We detected a response for this recipient or you already answered them, therefore we’ll skip the followup.';
      case 'error_sn_inbox_disabled':
        return 'Sorry, you can’t send a reply because this recipient is inactive.';
      case 'error_unfriended':
        return 'Internal error has occurred, please try resending this message through your regular SalesNav Inbox.';
      case 'error_sending':
        return 'Internal error has occurred, please try resending this message through your regular SalesNav Inbox.';
      default:
        return 'Internal error has occurred, please try resending this message through your regular SalesNav Inbox.';
    }
  };

  getWaitingToBeSendInfoMessage = (message: any) => {
    if (message?.status !== 'created') {
      return '';
    }
    if (!this.props.client.enabled) {
      return 'Please turn the main enable button ON to send this message';
    } else if (checkCloudOffline(this.props.client?.last_activity_date, this.props.serverState)) {
      return `Please contact ${brandConfig.contact} to check the system. Mention error server_offline`;
    } else if (isUserWithRecentLoginProblem(this.props.user)) {
      return 'User has some problems to login, please resolve login and this message will be sent within 24 hours from resolution';
    }
    return '';
  };

  onSetMessageStatus = (status: string) => {
    const {message} = this.props;
    this.props.updateMessageStatus({
      id: message.id,
      status,
    });
  };

  getMemberName = (memberId, members) => {
    return members.find(m => m.id === memberId)?.name;
  };

  highlightNoteMessage = message => {
    return message.replace(/(?:^|\W)@([a-zA-Z0-9_ ]+)(?!\w)/g, " <span class='conversation__message-note-highlight'>@$1</span>");
  };

  render() {
    const {
      message,
      user,
      addCustomEmailToUserReceiver,
      addCustomPhoneToUserReceiver,
      userReceiverId,
      client,
      members,
    } = this.props;
    const isMessageWithError = message.status && message.status.includes('error');
    const waitingToBeSendMessage = this.getWaitingToBeSendInfoMessage(message);
    const date = message.sent_date ? message.sent_date : message.creation_date;

    if (['meta', 'event'].includes(message.kind)) {
      return (
        <span className={`conversation__message-meta ${message.kind === 'event' && 'event'}`}>{message.content}</span>
      );
    }
    if (message.kind === 'note') {
      return (
        <div className={`conversation__message-note`}>
          <div dangerouslySetInnerHTML={{__html: this.highlightNoteMessage(message.content)}} />

          <div className="conversation__note-date">
            <span>{moment(new Date(date)).format(CONSTANTS.main_date_format)}</span>
            <span>
              {' '}
              •{' '}
              {`Note from ${
                message.team_member ? this.getMemberName(message.team_member, members) : client.name
              }`}
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className={`conversation__message ${message.sent_by_receiver ? 'receiver' : 'user'}`}>
        <MessageContent
          addCustomEmailToUserReceiver={addCustomEmailToUserReceiver}
          addCustomPhoneToUserReceiver={addCustomPhoneToUserReceiver}
          trackingLinks={this.props.trackingLinks}
          message={message}
          userReceiverId={userReceiverId}
          getPreview={this.props.getPreview}
        />
        {message?.attachments?.length ? (
          <ul style={{float: message.content ? 'right' : 'none'}}>
            {message?.attachments?.map(({attachment_name}) => (
              <ConversationMessageAttachment attachment_name={attachment_name} messageContent={message.content} />
            ))}
          </ul>
        ) : null}
        <div className="conversation__message-date">
          {message.status ? (
            <span className="conversation__message-status">
              {this.getStatusDescription(message.status)}
              {isMessageWithError && (
                <>
                  <InfoIcon
                    type="warning"
                    color="#faad13"
                    overlayClassName="tooltip-warning"
                    style={{marginLeft: 5}}
                    message={this.getErrorMessage(message.status, user)}
                  />
                  <Button
                    onClick={() => this.onSetMessageStatus('created')}
                    size="small"
                    ghost
                    className="conversation__message-action"
                  >
                    resend
                  </Button>
                  <Button
                    onClick={() => this.onSetMessageStatus('deleted')}
                    size="small"
                    ghost
                    className="conversation__message-action"
                  >
                    remove
                  </Button>
                </>
              )}
              {waitingToBeSendMessage && (
                <InfoIcon
                  type="warning"
                  color="#faad13"
                  overlayClassName="tooltip-warning"
                  style={{marginLeft: 5}}
                  message={waitingToBeSendMessage}
                />
              )}
            </span>
          ) : null}
          <span>{moment(new Date(date)).format(CONSTANTS.main_date_format)}</span>
          {message.inbox && <span> • {`${message.inbox.toUpperCase()} Inbox`}</span>}
        </div>
      </div>
    );
  }
}
