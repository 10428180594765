import {Template} from '@growth-x/types';

import {TemplateService} from '../../services';

export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE';
export const CREATE_TEMPLATE_REQUEST = 'CREATE_TEMPLATE_REQUEST';
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS';
export const CREATE_TEMPLATE_FAILURE = 'CREATE_TEMPLATE_FAILURE';
export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';
export const UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE';
export const TOGGLE_TEMPLATE_MODAL = 'TOGGLE_TEMPLATE_MODAL';
export const SET_EDIT_TEMPLATE = 'SET_EDIT_TEMPLATE';

export const templateActions = {
  getTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  toggleTemplateModal,
  setEditTemplate,
  saveTemplate,
};

function getTemplates() {
  return async (dispatch: any) => {
    dispatch(request());
    try {
      const result = await TemplateService.getAllTemplates();
      dispatch(success(result.data));
    } catch (e) {
      dispatch(failure());
    }
  };

  function request() {
    return {type: GET_TEMPLATES_REQUEST};
  }
  function success(templates: Template[]) {
    return {type: GET_TEMPLATES_SUCCESS, templates};
  }
  function failure() {
    return {type: GET_TEMPLATES_FAILURE};
  }
}

function createTemplate(template: Template) {
  return async (dispatch: any) => {
    dispatch(request());
    try {
      const result = await TemplateService.createTemplate(template);
      dispatch(success(result.data));
    } catch (e) {
      dispatch(failure());
    }
  };

  function request() {
    return {type: CREATE_TEMPLATE_REQUEST};
  }
  function success(template: any) {
    return {type: CREATE_TEMPLATE_SUCCESS, template};
  }
  function failure() {
    return {type: CREATE_TEMPLATE_FAILURE};
  }
}

function updateTemplate(template: Template) {
  return async (dispatch: any) => {
    dispatch(request());
    try {
      const result = await TemplateService.updateTemplate(template);
      dispatch(success(result.data));
    } catch (e) {
      dispatch(failure());
    }
  };

  function request() {
    return {type: UPDATE_TEMPLATE_REQUEST};
  }
  function success(template: any) {
    return {type: UPDATE_TEMPLATE_SUCCESS, template};
  }
  function failure() {
    return {type: UPDATE_TEMPLATE_FAILURE};
  }
}

function saveTemplate(template: Template) {
  return async (dispatch: any) => {
    if (template.id) {
      dispatch(updateTemplate(template));
    } else {
      dispatch(createTemplate(template));
    }
  };
}

function deleteTemplate(template: Template) {
  return async (dispatch: any) => {
    dispatch(request());
    try {
      await TemplateService.deleteTemplate(template.id);
      dispatch(success(template.id));
    } catch (e) {
      dispatch(failure());
    }
  };

  function request() {
    return {type: DELETE_TEMPLATE_REQUEST};
  }
  function success(id: number) {
    return {type: DELETE_TEMPLATE_SUCCESS, id};
  }
  function failure() {
    return {type: DELETE_TEMPLATE_FAILURE};
  }
}

function toggleTemplateModal(templateMessage) {
  return (dispatch: any) => {
    dispatch({
      type: TOGGLE_TEMPLATE_MODAL, 
      template: templateMessage ? {title: '', text: templateMessage} : undefined
    });
  };
}

function setEditTemplate(template: Template) {
  return (dispatch: any) => {
    dispatch({type: SET_EDIT_TEMPLATE, template});
  };
}
