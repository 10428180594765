import {message} from 'antd';

import {brandConfig} from '@growth-x/ui';

import {BillingService} from '../../services';
import {clientActions} from '../client';

export const GET_BILLING_INFO_REQUEST = 'GET_BILLING_INFO_REQUEST';
export const GET_BILLING_INFO_SUCCESS = 'GET_BILLING_INFO_SUCCESS';
export const GET_BILLING_INFO_FAILURE = 'GET_BILLING_INFO_FAILURE';
export const SET_BILLING_LOADING = 'SET_BILLING_LOADING';
export const BILLING_CONTACTS_REQUEST = 'BILLING_CONTACTS_REQUEST';
export const BILLING_CONTACTS_FAILURE = 'BILLING_CONTACTS_FAILURE';
export const GET_BILLING_CONTACTS_SUCCESS = 'GET_BILLING_CONTACTS_SUCCESS';
export const CHECK_PROMO_TOKEN_REQUEST = 'CHECK_PROMO_TOKEN_REQUEST';
export const CHECK_PROMO_TOKEN_FAILURE = 'CHECK_PROMO_TOKEN_FAILURE';
export const CHECK_PROMO_TOKEN_SUCCESS = 'CHECK_PROMO_TOKEN_SUCCESS';

export const billingActions = {
  getBillingInfo,
  updateSubscription,
  updateSubscriptionLeadoku,
  updateSubscriptionStatus,
  removeBillingCoupon,
  getBillingContactEmails,
  createBillingContactEmail,
  updateBillingContactEmail,
  deleteBillingContactEmail,
  upsertBillingContactEmail,
  checkLeadokuPromoToken,
  moveFromPromoPlan,
  verifyCancellation,
  leadokuReactivate,
  removeScheduledChanges,
  endTrial,
  startTrial,
};

function getBillingInfo() {
  return async (dispatch: any) => {
    try {
      dispatch(request());
      const result = await BillingService.getSubscriptionInfo();
      dispatch(success(result.data));
    } catch (e) {
      dispatch(failure(JSON.parse(e?.request?.response)));
    }
  };

  function request() {
    return {type: GET_BILLING_INFO_REQUEST};
  }
  function success(info: any) {
    return {type: GET_BILLING_INFO_SUCCESS, info};
  }
  function failure(error: string) {
    return {type: GET_BILLING_INFO_FAILURE, error};
  }
}

function getErrorMessage(error, currentMes = '') {
  for (const key in error) {
    if (typeof error[key] === 'object') {
      return getErrorMessage(error[key], currentMes);
    } else {
      return `${currentMes} ${currentMes ? ', ' : ''} ${error[key]}`;
    }
  }
}

function updateSubscription(params) {
  return async (dispatch: any) => {
    try {
      dispatch({type: SET_BILLING_LOADING, loading: true});
      await BillingService.updateSubscription(params);
      message.success('Subscription successfully updated');
      setTimeout(() => {
        dispatch(getBillingInfo());
      }, 6000);
    } catch (e) {
      dispatch({type: SET_BILLING_LOADING, loading: false});
      const errorMes = getErrorMessage(JSON.parse(e?.request?.response));
      message.error(errorMes);
    }
  };
}

function updateSubscriptionLeadoku(params) {
  return async (dispatch: any) => {
    try {
      dispatch({type: SET_BILLING_LOADING, loading: true});
      await BillingService.updateSubscriptionLeadoku(params);
      message.success('Subscription successfully updated');
      setTimeout(() => {
        dispatch(clientActions.getClient());
        dispatch(getBillingInfo());
      }, 6000);
    } catch (e) {
      dispatch({type: SET_BILLING_LOADING, loading: false});
      const errorMes = getErrorMessage(JSON.parse(e?.request?.response));
      message.error(errorMes);
    }
  };
}

function endTrial() {
  return async (dispatch: any) => {
    try {
      dispatch({type: SET_BILLING_LOADING, loading: true});
      await BillingService.endTrial();
      message.success('Subscription successfully updated');
      setTimeout(() => {
        dispatch(clientActions.getClient());
        dispatch(getBillingInfo());
      }, 10000);
    } catch (e) {
      dispatch({type: SET_BILLING_LOADING, loading: false});
      const errorMes = getErrorMessage(JSON.parse(e?.request?.response));
      message.error(errorMes);
    }
  };
}

function removeBillingCoupon(params) {
  return async () => {
    try {
      await BillingService.removeCoupon(params);
    } catch (e) {
      const errorMes = getErrorMessage(JSON.parse(e?.request?.response));
      message.error(errorMes);
    }
  };
}

function updateSubscriptionStatus(status: string, params) {
  return async (dispatch: any) => {
    dispatch({type: SET_BILLING_LOADING, loading: true});
    console.log('update status ', status);
    try {
      switch (status) {
        // reactivate: canceled -> active (Reactivate)
        case 'reactivate': {
          await BillingService.updateSubscription({reactivate: true, ...params});
          break;
        }
        // move_from_on_hold: on_hold -> active (Reactivate)
        case 'move_from_on_hold': {
          await BillingService.updateSubscription({move_from_on_hold: true, ...params});
          break;
        }
        // cancel: active -> non_renewing (Cancel)
        case 'cancel': {
          // str or do not include
          await BillingService.cancelSubscription(params);
          if (params?.reason) {
            message.success('Your account will be disabled at the end of your trial period', 5);
          }
          break;
        }
        // remove_scheduled_cancellation: non_renewing -> active (Reactivate)
        case 'remove_scheduled_cancellation': {
          await BillingService.removeScheduledCancelation();
          break;
        }
        // on-hold: active -> on_hold_scheduled (Cancel)
        case 'on-hold': {
          await BillingService.putOnHold(params);
          break;
        }
        // remove_scheduled_on_hold: on_hold_scheduled -> active (Reactivate)
        case 'remove_scheduled_on_hold': {
          await BillingService.removeScheduledOnHold();
          break;
        }
      }
    } catch (err) {
      message.error(`Sorry, something goes wrong, try again later.`, 5);
      dispatch({type: SET_BILLING_LOADING, loading: false});
    } finally {
      dispatch(getBillingInfo());
    }
  };
}

function billingContactRequest() {
  return {type: BILLING_CONTACTS_REQUEST};
}

function billingContactFailure(error: string) {
  return {type: BILLING_CONTACTS_FAILURE, error};
}

function getBillingContactEmails() {
  return async (dispatch: any) => {
    try {
      dispatch(billingContactRequest());
      const result = await BillingService.getBillingContactEmails();
      dispatch(success(result.data));
    } catch (error) {
      message.error(`Sorry, something goes wrong, can't get the contact emails.`, 5);
      dispatch(billingContactFailure(error));
    }
  };

  function success(data: any) {
    return {type: GET_BILLING_CONTACTS_SUCCESS, data};
  }
}

function createBillingContactEmail(email) {
  return async (dispatch: any) => {
    try {
      dispatch(billingContactRequest());
      await BillingService.createBillingContactEmail(email);
      dispatch(getBillingContactEmails());
      message.success(`${email} added to contacts email list`, 5);
    } catch (error) {
      showError('create', error?.response?.data);
      dispatch(billingContactFailure(error));
    }
  };
}

function updateBillingContactEmail({email, contact_id}) {
  return async (dispatch: any) => {
    try {
      dispatch(billingContactRequest());
      await BillingService.updateBillingContactEmail({email, contact_id});
      dispatch(getBillingContactEmails());
      message.success(`Contact email updated with ${email}`, 5);
    } catch (error) {
      showError('update', error?.response?.data);
      dispatch(billingContactFailure(error));
    }
  };
}

function deleteBillingContactEmail(contact_id) {
  return async (dispatch: any) => {
    try {
      dispatch(billingContactRequest());
      await BillingService.deleteBillingContactEmail(contact_id);
      dispatch(getBillingContactEmails());
      message.success(`The email address removed from the contact email list`, 5);
    } catch (error) {
      showError('delete', error?.response?.data);
      dispatch(billingContactFailure(error));
    }
  };
}

function upsertBillingContactEmail(email) {
  return async (dispatch: any) => {
    try {
      dispatch(billingContactRequest());
      await BillingService.upsertBillingContactEmail(email);
      dispatch(getBillingContactEmails());
      message.success(`${email} added / updated on billing address email`, 5);
    } catch (error) {
      showError('update/create', error?.response?.data);
      dispatch(billingContactFailure(error));
    }
  };
}

function showError(action, error) {
  const {email, contact_id} = error;
  let errorMessage = `Sorry, something goes wrong, can't ${action} email.`;
  if (email?.length > 0) {
    errorMessage = email[0].message;
  }
  if (contact_id?.length > 0) {
    errorMessage = contact_id[0].message;
  }
  message.error(errorMessage, 5);
}

function checkLeadokuPromoToken(token) {
  return async (dispatch: any) => {
    dispatch(request());
    if (!token) {
      dispatch(failure('Something goes wrong. Please, ask administrator resend invitation.'));
      return;
    }
    return BillingService.purchaseLeadokuPromo(token).then(
      data => {
        dispatch(success(data?.data?.url));
      },
      error => {
        const message = error?.response?.data?.message;
        dispatch(failure(message || `Something goes wrong, please, contact ${brandConfig.contact}.`));
      }
    );
  };

  function request() {
    return {type: CHECK_PROMO_TOKEN_REQUEST};
  }
  function success(url) {
    return {type: CHECK_PROMO_TOKEN_SUCCESS, url};
  }
  function failure(error: string) {
    return {type: CHECK_PROMO_TOKEN_FAILURE, error};
  }
}

function moveFromPromoPlan(reactivate: boolean | undefined) {
  return async (dispatch: any) => {
    try {
      dispatch({type: SET_BILLING_LOADING, loading: true});
      await BillingService.moveFromPromoPlan(reactivate);
      setTimeout(() => {
        dispatch(getBillingInfo());
        dispatch(clientActions.getClient());
        message.success('Subscription was successfully updated', 10);
      }, 6000);
    } catch (error) {
      if (error?.response?.data?.error === 'payment_error') {
        message.error(
          'Your payment method is invalid or lacks sufficient funds. Please update it by navigating to Billing > Payment Methods in the menu and try again.',
          10
        );
      } else {
        message.error('Something went wrong. Please, try again later', 10);
      }
      dispatch({type: SET_BILLING_LOADING, loading: false});
    }
  };
}

function verifyCancellation() {
  return async () => {
    try {
      const result = await BillingService.verifyCancellation();
      return result.data;
    } catch (e) {
      message.error(`Sorry, something goes wrong, can't verify cancellation.`, 5);
    }
  };
}

function leadokuReactivate(params) {
  return async dispatch => {
    try {
      dispatch({type: SET_BILLING_LOADING, loading: true});
      const result = await BillingService.leadokuReactivate(params);
      setTimeout(() => {
        dispatch(getBillingInfo());
        message.success('Subscription was successfully updated', 10);
      }, 6000);
      return result.data;
    } catch (error) {
      if (error?.response?.data?.error && error?.response?.data?.error === 'payment_error') {
        message.error('Sorry, there is a problem with payment method. Please, check your payment methods.', 5);
      } else {
        message.error(`Sorry, something goes wrong, can't reactivate subscription.`, 5);
      }
      dispatch({type: SET_BILLING_LOADING, loading: false});
    }
  };
}

function removeScheduledChanges() {
  return async dispatch => {
    try {
      dispatch({type: SET_BILLING_LOADING, loading: true});
      const result = await BillingService.removeScheduledChanges();
      setTimeout(() => {
        dispatch(getBillingInfo());
      }, 6000);
      return result.data;
    } catch (error) {
      message.error(`Sorry, something goes wrong, can't cancel scheduled changes.`, 5);
      dispatch({type: SET_BILLING_LOADING, loading: false});
    }
  };
}

function startTrial() {
  return async (dispatch: any) => {
    try {
      dispatch({type: SET_BILLING_LOADING, loading: true});
      await BillingService.startTrial();
      setTimeout(() => {
        dispatch(clientActions.getClient());
        dispatch(getBillingInfo());
      }, 10000);
    } catch (e) {
      dispatch({type: SET_BILLING_LOADING, loading: false});
      const errorMes = getErrorMessage(
        JSON.parse(e?.request?.response),
        "Sorry, something goes wrong, can't start trial."
      );
      message.error(errorMes);
    }
  };
}
