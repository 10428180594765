import * as React from 'react';
import {Tooltip} from 'antd';
import {UserRequirementsInfo} from './userRequirementInfo';

export function UserRequirementsTooltip({children}) {
  return (
    <Tooltip
      trigger="click"
      className="user-requirements"
      title={<UserRequirementsInfo />}
      getPopupContainer={trigger => trigger}
      placement="rightBottom"
    >
      {children}
    </Tooltip>
  );
}
