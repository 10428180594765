import * as React from 'react';
import {brandConfig} from '@growth-x/ui';

export function UserRequirementsInfo() {
  return (
    <div>
      To safely use and take advantage of {brandConfig.name}, <b>your LinkedIn account must</b>:
      <br />
      <br />
      👉 Have <b>500 connections</b>. If the user has less than this amount/time period, it's risky to start automation
      as LinkedIn could detect a sudden spike of activity and raise a flag.
      <br />
      <br />
      👉 Have a <b>Sales Navigator</b> (LinkedIn Premium) account enabled.
      <br />
      <br />
      👉 Have the LinkedIn account's default language set to <b>English</b> (here's how).
      <br />
      <br />
      👉 <b>Not</b> be using any other automation tools (if others used in the past, at least a break of a month between
      the two).
      <br />
      <br />
      👉 Be a <b>real person</b>
      <br />
      <br />
      👉 <b>Not</b> be a duplicate of a real user.
      <br />
      <br />
      👉 Be <b>verified</b> with a verification code from LinkedIn.
      <br />
      <br />
      If you need assistance along the way, please feel free to message us within the app or email us at{' '}
      <a href={`mailto:${brandConfig.web}`}>{brandConfig.web}</a>.
    </div>
  );
}
