import {Button, Modal} from 'antd';
import React from 'react';
import {isMobile} from 'react-device-detect';
import './trialFinishedModal.scss';

import {openLeadokuSubscription} from '../../utils';

export function TrialFinishedModal({onClose, client}) {
  const onSubscribe = () => {
    openLeadokuSubscription(client, () => {});
    onClose();
  }
  return (
    <Modal
      visible={true}
      footer={null}
      onCancel={onClose}
      destroyOnClose={true}
      width={isMobile ? '100%' : 800}
    >
      <h3 className='trial-finished-modal__header'>Your trial has ended</h3>

      <p className="trial-finished-modal__description">
        Please{' '} 
        <Button type="link" onClick={onSubscribe}>
          subscribe
        </Button>{' '}
        to continue
      </p>
    </Modal>
  );
}
