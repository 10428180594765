import {
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILURE,
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_SUCCESS,
  TOGGLE_TEMPLATE_MODAL,
  SET_EDIT_TEMPLATE,
} from './templateActions';

const defaultTemplate = {title: '', text: ''};

const initialState: any = {
  list: [],
  listLoading: false,
  newTemplateLoading: false,
  showTemplateModal: false,
  editTemplate: defaultTemplate,
  listError: '',
};

export const templatesReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        list: action.templates,
        listError: '',
        listLoading: false,
      };
    case GET_TEMPLATES_REQUEST:
      return {
        ...state,
        listLoading: true,
      };
    case GET_TEMPLATES_FAILURE:
      return {
        ...state,
        listError: action.error,
        listLoading: false,
      };
    case CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        newTemplateLoading: false,
        list: [...state.list, action.template],
      };
    case CREATE_TEMPLATE_REQUEST:
      return {
        ...state,
        newTemplateLoading: true,
      };
    case CREATE_TEMPLATE_FAILURE:
      return {
        ...state,
        newTemplateLoading: false,
      };
    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.template.id) {
            return action.template;
          }
          return item;
        }),
      };
    case UPDATE_TEMPLATE_REQUEST:
      return {
        ...state,
      };
    case UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,
      };
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(template => template.id !== action.id),
      };
    case TOGGLE_TEMPLATE_MODAL:
      return {
        ...state,
        showTemplateModal: !state.showTemplateModal,
        editTemplate: action.template || state.editTemplate,
      };
    case SET_EDIT_TEMPLATE:
      return {
        ...state,
        editTemplate: action.template,
      };
    default:
      return state;
  }
};
