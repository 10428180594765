import {message} from 'antd';
import React from 'react';

import {Campaign, User} from '@growth-x/types';
import {
  findTagColumns,
  getCampaignErrorMessageByCode,
  isCampaignRetention,
  isCampaignMessageReqeust,
  brandConfig,
  isCampaignInmail,
  isCampaignAcquisition,
} from '@growth-x/ui';

import {CampaignsService, TaskService, UsersService, AudienceTreeService} from '../../services';
import {clearObjectEmptyFields} from '../../utils';
import {notificationsActions} from '../notifications';
import {settingsActions, SET_SETTINGS} from '../settings';
import {WS_CAMPAIGN_CREATED, WS_CAMPAIGN_UPDATED} from '../websocket';

export const GET_CAMPAIGNS_REQUEST = 'CAMPAIGN_STATS_REQUEST';
export const GET_CAMPAIGNS_SUCCESS = 'CAMPAIGN_STATS_SUCCESS';
export const GET_CAMPAIGNS_FAILURE = 'CAMPAIGN_STATS_FAILURE';
export const CREATE_CAMPAIGN_REQUEST = 'CREATE_CAMPAIGN_REQUEST';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';
export const UPDATE_CAMPAIGN_REQUEST = 'UPDATE_CAMPAIGN_REQUEST';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAILURE = 'UPDATE_CAMPAIGN_FAILURE';
export const ARCHIVE_CAMPAIGN_SUCCESS = 'ARCHIVE_CAMPAIGN_SUCCESS';
export const ARCHIVE_CAMPAIGN_FAILURE = 'ARCHIVE_CAMPAIGN_FAILURE';
export const UNARCHIVE_CAMPAIGN_SUCCESS = 'UNARCHIVE_CAMPAIGN_SUCCESS';
export const UNARCHIVE_CAMPAIGN_FAILURE = 'UNARCHIVE_CAMPAIGN_FAILURE';
export const DUPLICATE_CAMPAIGN_SUCCESS = 'DUPLICATE_CAMPAIGN_SUCCESS';
export const DUPLICATE_CAMPAIGN_FAILURE = 'DUPLICATE_CAMPAIGN_FAILURE';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAILURE = 'DELETE_CAMPAIGN_FAILURE';
export const SET_CAMPAIGNS_FILTERS = 'SET_CAMPAIGNS_FILTERS';
export const SET_CAMPAIGNS_FILTERS_DONE = 'SET_CAMPAIGNS_FILTERS_DONE';
export const SET_CAMPAIGNS_STATS = 'SET_CAMPAIGNS_STATS';
export const SET_CAMPAIGNS_STATS_MODE = 'SET_CAMPAIGNS_STATS_MODE';
export const GET_CAMPAIGNS_GRAPH_REQUEST = 'GET_CAMPAIGNS_GRAPH_REQUEST';
export const GET_CAMPAIGNS_GRAPH_SUCCESS = 'GET_CAMPAIGNS_GRAPH_SUCCESS';
export const GET_CAMPAIGNS_GRAPH_FAILURE = 'GET_CAMPAIGNS_GRAPH_FAILURE';
export const SET_CAMPAIGHS_GRAPH_OPTIONS = 'SET_CAMPAIGHS_GRAPH_OPTIONS';
export const UPDATE_CAMPAIGNS = 'UPDATE_CAMPAIGNS';
export const SET_SHOWN_CAMPAIGN_COLUMNS = 'SET_SHOWN_CAMPAIGN_COLUMNS';
export const UPDATE_FILTERED_CAMPAIGNS_IF_NEEDED = 'UPDATE_FILTERED_CAMPAIGNS_IF_NEEDED';
export const GET_CAMPAIGN_AUDIENCE_STATS_REQUEST = 'CAMPAIGN_AUDIENCE_STATS_REQUEST';
export const GET_CAMPAIGN_AUDIENCE_STATS_SUCCESS = 'CAMPAIGN_AUDIENCE_STATS_SUCCESS';
export const GET_CAMPAIGN_AUDIENCE_STATS_FAILURE = 'CAMPAIGN_AUDIENCE_STATS_FAILURE';
export const GET_CAMPAIGN_CLICKED_LINKS_STATS_REQUEST = 'CAMPAIGN_CLICKED_LINKS_STATS_REQUEST';
export const GET_CAMPAIGN_CLICKED_LINKS_STATS_SUCCESS = 'CAMPAIGN_CLICKED_LINKS_STATS_SUCCESS';
export const GET_CAMPAIGN_CLICKED_LINKS_STATS_FAILURE = 'CAMPAIGN_CLICKED_LINKS_STATS_FAILURE';
export const PARSE_SEARCH_URL_REQUEST = 'PARSE_SEARCH_URL_REQUEST';
export const PARSE_SEARCH_URL_FAILURE = 'PARSE_SEARCH_URL_FAILURE';
export const PARSE_SEARCH_URL_SUCCESS = 'PARSE_SEARCH_URL_SUCCESS';

export const campaignsActions = {
  getCampaigns,
  createCampaign,
  updateCampaign,
  deleteCampaign,
  addUsers,
  removeUsers,
  updateCampaignActions,
  clearCampaignError,
  clearCampaignWarning,
  archive,
  unarchive,
  duplicate,
  campaignCreatedWS,
  campaignUpdatedWS,
  campaignAddUserWS,
  campaignRemoveUserWS,
  updateCampaignError,
  loadCampaignStatistic,
  getCampaignById,
  filterCampaigns,
  setStatsMode,
  updateStatsGraph,
  changeShowCampaignsColumns,
  getCampaignAudienceStats,
  getCampaignClickedLinksStats,
  runCampaignImidiately,
  rescanCampaign,
  rescanRetentionTagCampaign,
  parseSearchUrl,
  resetSearchUrlFilters,
  getFollowupMessageSuggestion,
};

function getCampaigns(clientId: number, archive_mode = 'unarchived_only') {
  return async (dispatch: any, getState: any) => {
    dispatch(request());
    try {
      const {data} = await CampaignsService.getCampaignsByClient(clientId, archive_mode);
      const campaignUsers = await CampaignsService.getCampaignUserByClient(clientId, archive_mode).then(
        ({data}: any) => data.objects
      );

      const campaigns = data.campaigns.map((campaign: any) => {
        campaign.stats = {};
        campaign.error = campaign.error || '';
        campaign.actions = prepareCampaignActions(campaign);
        campaign.campaign_users = campaignUsers.filter((user: any) => user.campaign === campaign.id);
        campaign.selectedUser = prepareCampaignUser(campaign, getState().users.list);
        return campaign;
      });
      dispatch(success(campaigns));

      const setDefaultUser = () => {
        const filters = getState().settings[clientId].campaignFilters;

        let users = filters.users;
        if (getState().client.team_member?.user) {
          users = filters.users.length > 0 ? filters.users : [getState().client.team_member.user.toString()];
        }
        return {...filters, users};
      };
      dispatch(filterCampaigns({...setDefaultUser(), archive_mode}));
      const statsMode = getState().campaigns.statsMode;
      if (statsMode) {
        dispatch(setStatsMode(statsMode));
      }
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return {type: GET_CAMPAIGNS_REQUEST};
  }
  function success(campaigns: any) {
    return {type: GET_CAMPAIGNS_SUCCESS, campaigns};
  }
  function failure(status: string) {
    return {type: GET_CAMPAIGNS_FAILURE, status};
  }
}

function archive(campaignId: number) {
  return async (dispatch: any) => {
    const result = await CampaignsService.archive(campaignId);
    if (result.status === 200) {
      dispatch({type: ARCHIVE_CAMPAIGN_SUCCESS, campaignId});
    } else {
      dispatch({type: ARCHIVE_CAMPAIGN_FAILURE, error: result.data && result.data.status});
    }
  };
}

function unarchive(campaignId: number) {
  return async (dispatch: any) => {
    const result = await CampaignsService.unarchive(campaignId);
    if (result.status === 200) {
      dispatch({type: UNARCHIVE_CAMPAIGN_SUCCESS, campaignId});
    } else {
      dispatch({type: UNARCHIVE_CAMPAIGN_FAILURE, error: result.data && result.data.status});
    }
  };
}

function duplicate(campaignId: number) {
  return async (dispatch: any, getState: any) => {
    const {data} = await CampaignsService.duplicate(campaignId);
    handleCampaignResult(data);
    if (data.campaigns.length) {
      const refreshedCampaign = await loadExtra(data.campaigns[0], getState().campaigns.campaignsStats);
      refreshedCampaign['isDuplicatedCampaign'] = true;
      dispatch(success(refreshedCampaign));
    } else {
      dispatch(failure(data));
    }
  };

  function success(campaign: Campaign) {
    return {type: DUPLICATE_CAMPAIGN_SUCCESS, campaign};
  }
  function failure(status: string) {
    return {type: DUPLICATE_CAMPAIGN_FAILURE, status};
  }
}

function createCampaign(campaign: Campaign) {
  const isFirstInMailCampaign = (newCampaign, allCampaigns) => {
    return isCampaignInmail(newCampaign) && !allCampaigns.find(c => isCampaignInmail(c));
  };
  const isFirstCamapginWithUrl = (allCampaigns, newCampaign) => {
    const isCampaignWithUrl = campaign => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return (
        (campaign.message && campaign.message.match(urlRegex)) ||
        (campaign.followup_message && campaign.followup_message.match(urlRegex)) ||
        (campaign.second_followup_message && campaign.second_followup_message.match(urlRegex)) ||
        (campaign.third_followup_message && campaign.third_followup_message.match(urlRegex))
      );
    };
    const isAnyCampaignWithUrlExist = allCampaigns.find(isCampaignWithUrl);
    const isNewCampaignWithUrl = isCampaignWithUrl(newCampaign);
    return !isAnyCampaignWithUrlExist && isNewCampaignWithUrl;
  };

  return async (dispatch: any, getState: any) => {
    dispatch(request(campaign));
    const {data}: any = await CampaignsService.create({
      ...campaign,
      audience_campaigns_ids: campaign.audience_campaigns_ids ? JSON.stringify(campaign.audience_campaigns_ids) : null,
      instantly_campaign_ids: campaign.instantly_campaign_ids ? JSON.stringify(campaign.instantly_campaign_ids) : null,
      audience_actions: campaign.audience_actions ? JSON.stringify(campaign.audience_actions) : null,
      audience_tags_ids: campaign.audience_tags_ids ? JSON.stringify(campaign.audience_tags_ids) : null,
      exclude_receivers_contacted_by: campaign.exclude_receivers_contacted_by
        ? JSON.stringify(campaign.exclude_receivers_contacted_by)
        : null,
    });
    if (!data || data.error) {
      fileIsWrongNotification(data);
      message.warning(`Campaign ${campaign.name} has been created with errors`, 5);
    } else {
      if (isFirstCamapginWithUrl(getState().campaigns.list, campaign)) {
        message.warning(
          'Links in your messages will be converted into trackable links, granting you visibility into who is interacting with your content. You can disable this from the settings'
        );
      }
      handleCampaignResult(data);
      if (data.campaigns.length) {
        if (isFirstInMailCampaign(campaign, getState().campaigns.list)) {
          const oldColumns = getState().settings.showCampaignColumns;
          if (!oldColumns.includes('responded_to_outreached')) {
            dispatch(settingsActions.setSettings({showCampaignColumns: [...oldColumns, 'responded_to_outreached']}));
          }
        }
        const refreshedCampaign = await loadExtra(data.campaigns[0], getState().campaigns.campaignsStats);
        dispatch(success(refreshedCampaign));
        message.success(
          <span id={data.campaigns.length === 1 ? 'first_campaign_created' : ''}>
            Campaign {campaign.name} has been created. Numbers will appear after 24 hours of enabling campaigns.
          </span>,
          5
        );
      } else {
        dispatch(failure(data));
      }
    }
  };

  function request(campaign: Campaign) {
    return {type: CREATE_CAMPAIGN_REQUEST, campaign};
  }
  function success(campaign: Campaign) {
    return {type: CREATE_CAMPAIGN_SUCCESS, campaign};
  }
  function failure(status: string) {
    return {type: CREATE_CAMPAIGN_FAILURE, status};
  }
}

function campaignCreatedWS(campaign: Campaign) {
  return async (dispatch: any, getState: any) => {
    const refreshedCampaign = await loadExtra(campaign, getState().campaigns.campaignsStats);
    dispatch({type: WS_CAMPAIGN_CREATED, campaign: refreshedCampaign});
  };
}

function updateCampaign(campaign: Campaign, isSearchUrlUpdated: boolean) {
  return async (dispatch: any, getState: any) => {
    dispatch(request(campaign));
    const {data}: any = await CampaignsService.update({
      ...campaign,
      audience_campaigns_ids: campaign.audience_campaigns_ids ? JSON.stringify(campaign.audience_campaigns_ids) : null,
      instantly_campaign_ids: campaign.instantly_campaign_ids ? JSON.stringify(campaign.instantly_campaign_ids) : null,
      audience_actions: campaign.audience_actions ? JSON.stringify(campaign.audience_actions) : null,
      audience_tags_ids: campaign.audience_tags_ids ? JSON.stringify(campaign.audience_tags_ids) : null,
      exclude_receivers_contacted_by: campaign.exclude_receivers_contacted_by
        ? JSON.stringify(campaign.exclude_receivers_contacted_by)
        : null,
    });
    if (!data || data.error) {
      fileIsWrongNotification(data);
      message.warning(`Campaign ${campaign.name} has been saved with errors`, 5);
    } else {
      handleCampaignResult(data);
      if (data.campaigns?.length) {
        const refreshedCampaign = await loadExtra(data.campaigns[0], getState().campaigns.campaignsStats);
        dispatch(success(refreshedCampaign));
        if (isSearchUrlUpdated) {
          const notificationMessage = `Campaign ${campaign.name}has been saved. As the search URL was changed, the campaign is currently being rescanned and it will take some time to show updated potential results.`;
          notificationsActions.createNotification({
            text: notificationMessage,
            type: 'warning',
            additional_data: {
              campaign_id: campaign.id,
            },
          });
          message.success(notificationMessage, 10);
        } else {
          message.success(`Campaign ${campaign.name} has been saved`, 5);
        }
      } else {
        dispatch(failure(data));
      }
    }
  };

  function request(campaign: Campaign) {
    return {type: UPDATE_CAMPAIGN_REQUEST, campaign};
  }
  function success(campaign: Campaign) {
    return {type: UPDATE_CAMPAIGN_SUCCESS, campaign};
  }
  function failure(status: string) {
    return {type: UPDATE_CAMPAIGN_FAILURE, status};
  }
}

function deleteCampaign(campaignId: number) {
  return async (dispatch: any) => {
    try {
      await CampaignsService.deleteCampaign(campaignId);
      dispatch({type: DELETE_CAMPAIGN_SUCCESS, campaignId});
    } catch (error) {
      const errorMsg = error.response?.data?.[0].message;
      message.error(`Something went wrong. ${errorMsg}`, 5);
      dispatch({type: DELETE_CAMPAIGN_FAILURE, error: errorMsg});
    }
  };
}

function loadCampaignStatistic(campaign: Campaign) {
  return async (dispatch: any, getState: any) => {
    const refreshedCampaign = await loadExtra(campaign, getState().campaigns.campaignsStats);
    dispatch({type: UPDATE_CAMPAIGN_SUCCESS, campaign: refreshedCampaign});
  };
}

function handleCampaignResult(result: any) {
  logProcessedReceivers(result.processed_csv_receivers);
  if (result.errors) {
    logErrors(result.errors);
  }
}

function fileIsWrongNotification(data: any) {
  if (data && data.error === 'bad_csv_columns') {
    message.warning(`Uploaded file has wrong columns, please follow guide to create the CSV file`, 5);
  } else {
    message.warning(`Something is wrong with uploaded file, please follow guide to create the CSV file`, 5);
  }
}

function logProcessedReceivers(processedReceivers: any) {
  if (processedReceivers) {
    message.info(`${processedReceivers} csv rows were successfully handled`, 5);
  }
}

function logErrors(errors: any) {
  const badUrlError = errors['bad_linkedin_url'] || 0;
  const missingUrlError = errors['missing_linkedin_url'] || 0;
  const unicodeError = errors['unicode_decode_error'] || 0;
  const count = badUrlError + missingUrlError + unicodeError;
  if (errors['reached_rows_limit']) {
    message.warning(
      `You tried to upload too big file, we handle up to 5000 rows, not all rows were handled. Please split this file`,
      5
    );
  }
  if (count > 0) {
    message.warning(
      `There are some errors while processing csv campaign. ${count} rows were skipped
    (${badUrlError ? badUrlError + ' rows with bad url ' : ''}
    ${badUrlError && missingUrlError ? ' and ' : ''}
    ${missingUrlError ? missingUrlError + ' rows with missing url' : ''}
    ${unicodeError && missingUrlError ? ' and ' : ''}
    ${unicodeError ? unicodeError + ' rows with broken unicode' : ''}
    )`,
      5
    );
  }
}

function campaignUpdatedWS(campaign: Campaign) {
  return async (dispatch: any, getState: any) => {
    const refreshedCampaign = await loadExtra(campaign, getState().campaigns.campaignsStats);
    dispatch({type: WS_CAMPAIGN_UPDATED, campaign: refreshedCampaign});
  };
}

function campaignRemoveUserWS({campaign_users}: {campaign_users: any[]}) {
  return async (dispatch: any, getState: any) => {
    campaign_users.forEach(elem => {
      const campaign = getState().campaigns.list.find((campaign: Campaign) => campaign.id === elem.id);
      if (campaign) {
        campaign.selectedUser = null;
      }
      dispatch({type: UPDATE_CAMPAIGN_SUCCESS, campaign});
    });
  };
}

function campaignAddUserWS({campaign_users}: {campaign_users: any[]}) {
  return async (dispatch: any, getState: any) => {
    campaign_users.forEach(elem => {
      const campaign = getState().campaigns.list.find((campaign: Campaign) => campaign.id === elem.id);
      const user = getState().users.list.find((user: User) => user.id === elem.user_id);
      if (campaign && user) {
        campaign.selectedUser = user;
        dispatch({type: UPDATE_CAMPAIGN_SUCCESS, campaign});
      }
    });
  };
}

function addUsers(campaign: Campaign, users: User[]) {
  return async (dispatch: any, getState: any) => {
    const parameters = {id: campaign.id, users};
    await CampaignsService.updateCampaign(parameters);
    campaign.selectedUser = users[0];
    const refreshedCampaign = await loadExtra(campaign, getState().campaigns.campaignsStats);
    dispatch({type: UPDATE_CAMPAIGN_SUCCESS, campaign: refreshedCampaign});
  };
}

function removeUsers(campaign: Campaign, users: User[]) {
  return async (dispatch: any, getState: any) => {
    const parameters = {id: campaign.id, users, remove_users_flag: true};
    await CampaignsService.updateCampaign(parameters);
    campaign.selectedUser = null;
    const refreshedCampaign = await loadExtra(campaign, getState().campaigns.campaignsStats);
    dispatch({type: UPDATE_CAMPAIGN_SUCCESS, campaign: refreshedCampaign});
  };
}

function clearCampaignError(campaign: Campaign) {
  return async (dispatch: any) => {
    campaign.error = '';
    campaign.error_code = '';
    await CampaignsService.updateError(campaign);
    dispatch({type: UPDATE_CAMPAIGN_SUCCESS, campaign});
  };
}

function clearCampaignWarning(campaign: Campaign) {
  return async (dispatch: any) => {
    campaign.warning = '';
    campaign.warning_code = '';
    await CampaignsService.updateWarning(campaign);
    dispatch({type: UPDATE_CAMPAIGN_SUCCESS, campaign});
  };
}

function updateCampaignActions(campaign: Campaign) {
  return async () => {
    await CampaignsService.updateActionAndError(campaign);
  };
}

function updateCampaignError(campaign: Campaign) {
  return async (dispatch: any) => {
    await CampaignsService.updateError(campaign);
    dispatch({type: UPDATE_CAMPAIGN_SUCCESS, campaign});
    let errorMsg = getCampaignErrorMessageByCode(campaign.error_code);
    errorMsg = errorMsg.replace('{brandConfig.contact}', brandConfig.contact);

    if (errorMsg) {
      notificationsActions.createNotification({
        text: `campaign "${campaign.name}": ${errorMsg}`,
        type: 'error',
        additional_data: {
          campaign_id: campaign.id,
        },
      });
    }
  };
}

function prepareCampaignActions(campaign: Campaign) {
  const actions = [];
  if (campaign.messages_active) actions.push(isCampaignRetention(campaign) ? 'N' : 'I');
  if (campaign.followups_active) actions.push('F');
  if (campaign.second_followups_active) actions.push('S');
  if (campaign.third_followups_active) actions.push('T');
  return actions;
}

function prepareCampaignUser(campaign: any, users: User[]) {
  // if active User is not defined or client has only one user pick up the first one available
  const activeUser = campaign.campaign_users[0];
  return activeUser ? users.find(u => u.id === activeUser.user) : null;
}

async function loadExtra(campaign: Campaign, allStats: any) {
  const campaignWithUser: Campaign = {
    ...populateCampaignWithStats(campaign, allStats),
  };

  const users = await CampaignsService.getCampaignUserByCampaign(campaign.id).then(({data}: any) => data.objects);
  campaignWithUser.actions = [];
  if (campaignWithUser.messages_active && campaignWithUser.messages_active !== '0') {
    campaignWithUser.actions.push(isCampaignRetention(campaign) ? 'N' : 'I');
  }
  if (campaignWithUser.followups_active && campaignWithUser.followups_active !== '0')
    campaignWithUser.actions.push('F');
  if (campaignWithUser.second_followups_active && campaignWithUser.second_followups_active !== '0')
    campaignWithUser.actions.push('S');
  if (campaignWithUser.third_followups_active && campaignWithUser.third_followups_active !== '0')
    campaignWithUser.actions.push('T');

  campaignWithUser.error = campaign.error || '';

  // if active User is not defined or client has only one user pick up the first one available
  const activeUser = users[0];
  if (activeUser) {
    campaignWithUser.selectedUser = activeUser.user;
  }

  return campaignWithUser;
}

function populateCampaignWithStats(campaign: Campaign, allStats: any) {
  const campaignWithStats: Campaign = {
    ...campaign,
    potential: 0,
    stats: {
      total_invites: 0,
      total_messages: 0,
      total_connections: 0,
      total_responses: 0,
      total_positive_responses: 0,
      creation_date: 0,
      deals_amount: 0,
      loaded: true,
      tags: {},
    },
  };
  if (allStats?.by_campaign && allStats?.by_campaign[campaign.id]) {
    const stats = allStats?.by_campaign[campaign.id];
    // sum individual campaign users totals to campaign
    campaignWithStats.stats.total_invites = stats.invite || 0;
    campaignWithStats.stats.total_connections = stats.connections || 0;
    campaignWithStats.stats.total_responses = stats.response || 0;
    campaignWithStats.stats.total_responders = stats.responders || 0;
    campaignWithStats.stats.total_messages = stats.nurtured || 0;
    campaignWithStats.potential = stats.potential || 0;
    campaignWithStats.stats.reach = stats.reach || 0;
    campaignWithStats.stats.total_positive_responses = stats.positive_responses || 0;
    campaignWithStats.stats.in_sequence = stats.in_sequence || 0;
    campaignWithStats.stats.tracking_clicks = stats.tracking_clicks || 0;
    campaignWithStats.stats.creation_date = stats.creation_date || 0;
    campaignWithStats.stats.deals_amount = stats.deals_amount || 0;
    campaignWithStats.stats.warning_connection_rate = stats.warning_connection_rate;
    campaignWithStats.stats.accepted_message_requests = stats.accepted_message_requests || 0;
    campaignWithStats.stats.message_request = stats.message_request || 0;
    campaignWithStats.stats.accepted_inmails = stats.accepted_inmails || 0;
    campaignWithStats.stats.rejected_inmails = stats.rejected_inmails || 0;
    campaignWithStats.stats.inmail = stats.inmail || 0;
    campaignWithStats.stats.responses_after_invite = stats.responses_after_invite || 0;
    campaignWithStats.stats.responses_after_followup = stats.responses_after_followup || 0;
    campaignWithStats.stats.responses_after_second_followup = stats.responses_after_second_followup || 0;
    campaignWithStats.stats.responses_after_third_followup = stats.responses_after_third_followup || 0;

    // these values should be 100% maximum
    campaignWithStats.stats.invites_to_connections_rate = Math.min(
      calculateRate(campaignWithStats.stats.total_connections, campaignWithStats.stats.total_invites),
      100
    );
    const totalCountForResponseRate = isCampaignRetention(campaign)
      ? campaignWithStats.stats.total_messages
      : isCampaignMessageReqeust(campaign)
      ? campaignWithStats.stats.accepted_message_requests
      : isCampaignInmail(campaign)
      ? campaignWithStats.stats.accepted_inmails
      : campaignWithStats.stats.total_connections;

    campaignWithStats.stats.connections_to_responses_rate = Math.min(
      calculateRate(campaignWithStats.stats.total_responders, totalCountForResponseRate),
      100
    );
    campaignWithStats.stats.positive_responders_rate = Math.min(
      calculateRate(campaignWithStats.stats.total_positive_responses, campaignWithStats.stats.total_responders),
      100
    );
    campaignWithStats.stats.tags = stats?.tags?.reduce((tagsObject, tag) => {
      for (const key in tag) {
        tagsObject = {...tagsObject, [key]: tag[key]};
      }

      return tagsObject;
    }, {});
    let outreached = 0;
    if (isCampaignRetention(campaign)) {
      outreached = campaignWithStats.stats.total_messages;
    } else if (isCampaignAcquisition(campaign)) {
      outreached = campaignWithStats.stats.total_invites;
    } else if (isCampaignInmail(campaign)) {
      outreached = campaignWithStats.stats.inmail;
    }
    campaignWithStats.stats.responded_to_outreached = Math.min(
      calculateRate(campaignWithStats.stats.total_responders, outreached),
      100
    );
  }
  return campaignWithStats;
}

function calculateRate(num: number, den: number) {
  let rate = (num / den) * 100;
  if (rate.toString() === 'Infinity' || rate.toString() === 'NaN') {
    rate = 0;
  }
  rate = Math.ceil(rate);
  return rate;
}

function getCampaignById(campaignId: number) {
  return async () => {
    if (!campaignId) return null;
    try {
      const result = await CampaignsService.getCampaignById(campaignId);
      return Promise.resolve(result.data);
    } catch (e) {
      return Promise.reject();
    }
  };
}

function setStatsMode(flag) {
  return async (dispatch: any, getState: any) => {
    dispatch({type: SET_CAMPAIGNS_STATS_MODE, value: flag});
    if (flag) {
      dispatch(updateStatsGraph(getState().campaigns.graphOptions, []));
    }
  };
}

function filterCampaigns(filters: any) {
  return async (dispatch: any, getState: any) => {
    try {
      const clientId = getState().client.data.id;
      const settings = getState().settings;
      settings[clientId] = {...settings[clientId], campaignFilters: filters};
      dispatch({type: SET_SETTINGS, settings});
      dispatch({type: SET_CAMPAIGNS_FILTERS});
      const columns = getState().settings.showCampaignColumns;
      const tags = filters?.tags || findTagColumns(columns);

      const params = {
        ...filters,
        tags,
        date: filters.date_range_start && filters.date_range_end ? 'date_range' : filters.date,
        users: filters.users.length ? filters.users : null,
        campaigns: filters.campaigns.length ? filters.campaigns : null,
        campaign_types: filters.campaign_types.length ? filters.campaign_types : null,
      };

      clearObjectEmptyFields(params);
      const {data} = await CampaignsService.getCampaignsStats(params);
      const campaignsWithStats = getState().campaigns.list.map(campaign => populateCampaignWithStats(campaign, data));
      dispatch(updateCampaigns(campaignsWithStats));
      dispatch({type: SET_CAMPAIGNS_STATS, stats: data});
      dispatch({type: SET_CAMPAIGNS_FILTERS_DONE});
    } catch (e) {
      return Promise.reject();
    }

    function updateCampaigns(campaigns: Campaign[]) {
      return {type: UPDATE_CAMPAIGNS, campaigns};
    }
  };
}

function updateStatsGraph(options: any[], campaignsSelected = []) {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({type: SET_CAMPAIGHS_GRAPH_OPTIONS, options});
      const clientId = getState().client.data.id;
      const filters = getState().settings[clientId].campaignFilters;

      const campaigns = filters.campaigns.length ? filters.campaigns.concat(campaignsSelected) : null;
      const params = {
        ...filters,
        date: filters.date_range_start && filters.date_range_end ? 'date_range' : filters.date,
        users: filters.users.length ? filters.users : null,
        campaign_types: filters.campaign_types.length ? filters.campaign_types : null,
        campaigns,
      };

      clearObjectEmptyFields(params);
      dispatch(request());

      const {data: graphData} = await CampaignsService.getCampaignsGraph({
        ...params,
        data_types: options,
      });
      dispatch(success(graphData));
    } catch (e) {
      dispatch(failure());
      return Promise.reject();
    }
    function request() {
      return {type: GET_CAMPAIGNS_GRAPH_REQUEST};
    }
    function success(graphData: Campaign) {
      return {type: GET_CAMPAIGNS_GRAPH_SUCCESS, graphData};
    }
    function failure() {
      return {type: GET_CAMPAIGNS_GRAPH_FAILURE};
    }
  };
}

function changeShowCampaignsColumns(columns: string[]) {
  return async (dispatch: any) => {
    dispatch({type: SET_SHOWN_CAMPAIGN_COLUMNS, columns});
  };
}

function getCampaignAudienceStats(campaignId) {
  return async (dispatch: any) => {
    dispatch(request());
    try {
      const {data} = await CampaignsService.getCampaignAudienceStats({campaign_id: campaignId});
      dispatch(success(data.stats || []));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return {type: GET_CAMPAIGN_AUDIENCE_STATS_REQUEST};
  }
  function success(stats: any) {
    return {type: GET_CAMPAIGN_AUDIENCE_STATS_SUCCESS, stats};
  }
  function failure(status: string) {
    return {type: GET_CAMPAIGN_AUDIENCE_STATS_FAILURE, status};
  }
}

function getCampaignClickedLinksStats(filters) {
  return async (dispatch: any) => {
    dispatch(request());
    try {
      const {data} = await CampaignsService.getCampaignClickedLinksStats(filters);
      dispatch(success(data || []));
    } catch (e) {
      dispatch(failure(e.message));
    }
  };

  function request() {
    return {type: GET_CAMPAIGN_CLICKED_LINKS_STATS_REQUEST};
  }
  function success(stats: any) {
    return {type: GET_CAMPAIGN_CLICKED_LINKS_STATS_SUCCESS, stats};
  }
  function failure(status: string) {
    return {type: GET_CAMPAIGN_CLICKED_LINKS_STATS_FAILURE, status};
  }
}

function runCampaignImidiately(campaignId: number) {
  return (dispatch: any) => {
    dispatch(request());
    TaskService.postTask({
      additional_data: {
        campaign_id: campaignId,
      },
      code: 'run_campaign_immediately',
      status: 'created',
    }).then(
      () => dispatch(success()),
      () => dispatch(failure())
    );
  };
  function request() {
    return {type: 'RUN_CAMPAIGN_IMMEDIATELY_REQUEST'};
  }
  function failure() {
    return {type: 'RUN_CAMPAIGN_IMMEDIATELY_FAILURE'};
  }
  function success() {
    return {type: 'RUN_CAMPAIGN_IMMEDIATELY__SUCCESS'};
  }
}

function rescanCampaign(campaignId: number, userId: number) {
  return async (dispatch: any) => {
    dispatch(request());
    await UsersService.rescanUserCampaign(campaignId, userId);
    await CampaignsService.updateLastScan(campaignId, true);
    await TaskService.postTask({
      additional_data: {
        campaign_id: campaignId,
      },
      code: 'run_campaign_immediately',
      status: 'created',
    }).then(
      () => dispatch(success()),
      () => dispatch(failure())
    );
  };
  function request() {
    return {type: 'RUN_CAMPAIGN_IMMEDIATELY_REQUEST'};
  }
  function failure() {
    return {type: 'RUN_CAMPAIGN_IMMEDIATELY_FAILURE'};
  }
  function success() {
    return {type: 'RUN_CAMPAIGN_IMMEDIATELY__SUCCESS'};
  }
}

function rescanRetentionTagCampaign(campaignId: number) {
  return async () => {
    try {
      await CampaignsService.rescanRetentionTagCampaign(campaignId);
      message.success('Campaign will be rescanned');
    } catch (e) {
      message.error('Something went wrong with rescanning');
    }
  };
}

function parseSearchUrl(searchUrl: string) {
  return async (dispatch: any) => {
    dispatch(request());
    const result = await AudienceTreeService.parseSearchUrl(searchUrl);
    if (result.error) {
      dispatch(failure(result));
    } else {
      dispatch(success(result));
    }
  };
  function request() {
    return {type: PARSE_SEARCH_URL_REQUEST};
  }
  function failure(error) {
    return {type: PARSE_SEARCH_URL_FAILURE, error};
  }
  function success(result) {
    return {type: PARSE_SEARCH_URL_SUCCESS, filters: result?.data};
  }
}

function resetSearchUrlFilters() {
  return async (dispatch: any) => {
    dispatch(success());
  };

  function success() {
    return {type: PARSE_SEARCH_URL_SUCCESS, filters: []};
  }
}

function getFollowupMessageSuggestion(parameters) {
  return async () => {
    try {
      clearObjectEmptyFields(parameters);
      const result = await CampaignsService.getFollowupMessageSuggestion(parameters);
      if (result?.data?.warning?.message) {
        message.warning(result?.data?.warning?.message);
      }

      return result.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        message.error(error?.response?.data?.error);
      } else {
        message.error('Something went wrong. Please try again later');
      }
    }
  };
}
