export class ClientMaintenanceService {
  client: any;
  serviceName = 'client_maintenance';
  constructor({client}: any) {
    this.client = client;
  }

  getAffiliatedClients() {
    return this.client.get(`${this.serviceName}/accounts`, {});
  }

  moveUser(params) {
    return this.client.post(`${this.serviceName}/move_user`, params);
  }

  forceMoveUser(params) {
    return this.client.post(`${this.serviceName}/force_create_user`, params);
  }
  getServerState() {
    return this.client.get(`api/v2/get-ci-state/`, {});
  }
}
