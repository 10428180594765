import * as React from 'react';
import {Popover} from 'antd';
import {UserRequirementsInfo} from './userRequirementInfo';

export function UserRequirementsPopover({children}) {
  return (
    <Popover
      trigger="click"
      className="user-requirements-popover"
      placement="top"
      style={{minWidth: 300}}
      getPopupContainer={trigger => trigger.parentElement.parentElement}
      content={<UserRequirementsInfo />}
    >
      {children}
    </Popover>
  );
}
